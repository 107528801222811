module.exports = {
  header: {
    forums: 'Форумы',
    logout: 'выйти',
    playNow: 'играть сейчас!',
    login: 'войти',
    cookies: {
      button: {
        decline: 'Продолжить только с необходимыми cookies.',
        accept: 'Принять все cookies.',
      },
      text:
        'Ryzom использует cookie-файлы для трекинга и в маркетинговых целях.',
    },
    webig: 'Веб-приложения',
    billing: 'Аккаунт',
  },
  menu: {
    forums: 'форумы',
    chat: 'чат',
    gameInfo: 'Об игре',
    universe: 'вселенная',
    wiki: 'wiki',
    events: 'события',
    apps: 'приложения',
    home: 'главная',
  },
  footer: {
    owner: 'Winch Gate Property Limited',
    copyright: 'Авторские права © {year}. Все права защищены.',
    pegi: { online: 'Онлайн геймплей', violence: 'Жестокость' },
    privacy: 'Политика Конфиденциальности',
    tos: 'Пользовательское соглашение',
    community: {
      bmsite: 'Ballistic Mystix',
      armory: 'Ryzom Armory',
      forge: 'Ryzom Forge',
      wiki: 'Ryzom Wiki',
      title: 'Сообщества',
    },
    follow: {
      youtube: 'Youtube',
      twitter: 'Twitter',
      vimeo: 'Vimeo',
      flickr: 'Flickr',
      facebook: 'Facebook',
      title: 'Подпишитесь на Ryzom',
    },
    support: {
      feedback: 'Обратная связь',
      forums: 'Форумы',
      contact: 'Контакты',
      title: 'Поддержка',
    },
  },
  premium: {
    button: { manageSub: 'Управление подпиской' },
    sub: { button: 'добавить подписку', title: 'Уже играете?' },
    compare: { title: 'Сравните! Выбор за вами.' },
    comparison: {
      premium: {
        maxlevel: 'Максимальный уровень 250',
        xp: 'Двойной Опыт',
        maxquality: 'Максимальное качество снаряжения 250',
        storage:
          '* Одно верховое животное и три вьючных\n* Одни апартаменты\n* Один зал гильдии',
        title: 'премиум',
        support:
          '* Помощь со стороны Команды Поддержки\n* Восстановление старых аккаунтов (с подпиской до слияния игровых серверов) с целью восстановления персонажей\n* Восстановление персонажа(ей) в случае добровольного или случайного удаления\n* Перенос персонажа(ей) между двумя Премиум-аккаунтами, принадлежащими одному и тому же лицу\n* Смена псевдонима разрешена каждые шесть месяцев для каждого персонажа.',
      },
      free: {
        maxlevel: 'Максимальный уровень 125',
        xp: 'Обычный Опыт',
        maxquality: 'Максимальное качество снаряжения 150',
        storage: 'Одно верховое животное',
        title: 'бесплатнo',
        support:
          '* Помощь со стороны Команды Поддержки\n* Для каждого персонажа разрешена смена псевдонима',
      },
    },
    intro: {
      text:
        'Ryzom открыл игру в мир Свободы, опубликовав свой код и став единственной MMORPG с открытым исходным кодом, в которую можно играть под Windows, Linux и Mac.\n\nОднако, обслуживание Ryzom связано с такими затратами, как оплата труда небольшой команды сотрудников и оплата серверов. Поэтому, несмотря на то, что вы можете играть бесплатно, подписка жизненно важна для продолжения функционирования Ryzom.\n\nЧтобы поблагодарить игроков, выбравших подписку, некоторые особенности игры для них разблокированы, а также им предлагаются определенные бонусы: возможность прокачивать своих персонажей до 250 уровня вместо 125, удвоение всех набранных очков опыта, а также доступ к нескольким другим способам хранения предметов.\n\n**Чтобы помочь Ryzom, сделай свой выбор в пользу подписки!**',
      title: 'Зачем платить?',
    },
    rates: {
      perMonth: 'месяц',
      w1: {
        title: '1 неделя подписки',
      },
      m1: { title: '1 месяц подписки' },
      m3: { title: '3 месяца подписки' },
      y1: { title: '1 год подписки' },
      m6: { title: '6 месяцев подписки' },
      y99: {
        title: 'Пожизненный подписчик',
      },
      title: 'Тарифы',
      lifetime: 'Персонажи на аккаунте пожизненный подписчик получают специальный титул "Посланник Рызома" / "Посланница Рызома".',
    },
    donate: {
      button: 'сделать взнос (donation)',
      title: 'Помоги Саге продолжаться!',
    },
    title: 'Поддержите Ryzom и получите выгоду!',
    signup: {
      button: 'Зарегистрируйтесь и начните играть',
      title: 'Интересуетесь Ryzom?',
    },
  },
};
