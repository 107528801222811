import { render, staticRenderFns } from "./footer-wrapper.vue?vue&type=template&id=1ecb25e6&shadow"
import script from "./footer-wrapper.vue?vue&type=script&lang=js&shadow"
export * from "./footer-wrapper.vue?vue&type=script&lang=js&shadow"
function injectStyles (context) {
  
  var style0 = require("./footer-wrapper.vue?vue&type=style&index=0&lang=scss&shadow")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports