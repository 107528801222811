<template>
  <footer id="layout-footer">
    <div
      :class="{ 'p-lg-5 p-2': !lowPadding, 'p-2': lowPadding }"
      class="footer-real"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-6 col-lg-4">
                <h5>{{ $t('footer.support.title') }}</h5>
                <div>
                  <a
                    :href="
                      'https://app.ryzom.com/app_forum/index.php?page=forum/view/1841&lang=' +
                        lang
                    "
                    target="_blank"
                    >{{ $t('footer.support.forums') }}</a
                  >
                </div>
                <div>
                  <a
                    :href="
                      'https://app.ryzom.com/app_forum/index.php?page=forum/view/1840&lang=' +
                        lang
                    "
                    target="_blank"
                    >{{ $t('footer.support.feedback') }}</a
                  >
                </div>
                <h5 class="mt-2">{{ $t('footer.support.contact') }}</h5>
                <div>
                  <a href="https://support.ryzom.com" target="_blank">support.ryzom.com</a>
                </div>
              </div>
              <div class="col-6 col-lg-4">
                <h5>{{ $t('footer.community.title') }}</h5>
                <div>
                  <a :href="forgeLink" target="_blank">{{
                    $t('footer.community.forge')
                  }}</a>
                </div>
                <div>
                  <a href="https://ballisticmystix.net/" target="_blank">{{
                    $t('footer.community.bmsite')
                  }}</a>
                </div>
                <div>
                  <a href="https://www.ryzomarmory.com/" target="_blank">{{
                    $t('footer.community.armory')
                  }}</a>
                </div>
              </div>
              <div class="col-lg-4 mt-4 mt-lg-0">
                <h5>{{ $t('footer.follow.title') }}</h5>
                <span class="font-x-large social-links"
                  ><a
                    href="https://www.facebook.com/pages/Ryzom/133886620535"
                    target="_blank"
                    ><font-awesome-icon
                      :icon="['fab', 'facebook']"
                      :title="$t('footer.follow.facebook')"
                      class="mr-2"
                    ></font-awesome-icon>
                  </a>
                  <a href="https://twitter.com/Ryzom" target="_blank"
                    ><font-awesome-icon
                      :icon="['fab', 'twitter-square']"
                      :title="$t('footer.follow.twitter')"
                      class="mr-2"
                    ></font-awesome-icon
                  ></a>
                  <a href="https://vimeo.com/ryzom" target="_blank"
                    ><font-awesome-icon
                      :icon="['fab', 'vimeo-square']"
                      :title="$t('footer.follow.vimeo')"
                      class="mr-2"
                    ></font-awesome-icon
                  ></a>
                  <a
                    href="https://www.flickr.com/photos/ryzom/sets/"
                    target="_blank"
                    ><font-awesome-icon
                      :icon="['fab', 'flickr']"
                      :title="$t('footer.follow.flickr')"
                      class="mr-2"
                    ></font-awesome-icon>
                  </a>
                  <a
                    href="https://www.youtube.com/user/Ryzomxxx/videos?view=0&sort=dd&flow=grid"
                    target="_blank"
                    ><font-awesome-icon
                      :icon="['fab', 'youtube']"
                      :title="$t('footer.follow.youtube')"
                    ></font-awesome-icon>
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-4 mt-4 mt-md-0">
            <h5 class="mb-0">
              {{ $t('footer.owner') }}
            </h5>
            <div class="mb-2">
              {{ $t('footer.copyright', { year: new Date().getFullYear() }) }}
            </div>
            <div>
              <a :href="privacyLink" class="mr-2"
                >{{ $t('footer.privacy') }}
              </a>
              <a :href="tosLink">{{ $t('footer.tos') }}</a>
            </div>

            <div class="d-flex align-items-start mt-2">
              <a href="https://pegi.info" target="_blank"
                ><b-img-lazy
                  :src="ciPath + '/assets/images/usk-pegi/pegi-12.png'"
                  alt="PEGI 12"
                  class="m-1"
                  title="PEGI 12"
                  width="55px"
              /></a>
              <b-img-lazy
                :alt="$t('footer.pegi.violence')"
                :src="ciPath + '/assets/images/usk-pegi/violence.png'"
                :title="$t('footer.pegi.violence')"
                class="m-1"
                width="55px"
              />
              <b-img-lazy
                :alt="$t('footer.pegi.online')"
                :src="ciPath + '/assets/images/usk-pegi/online-gameplay.png'"
                :title="$t('footer.pegi.online')"
                class="m-1"
                width="55px"
              />
              <a href="http://www.usk.de/" target="_blank"
                ><b-img-lazy
                  :src="ciPath + '/assets/images/usk-pegi/usk-12.png'"
                  alt="USK 12"
                  class="m-1"
                  title="USK 12"
                  width="55px"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <client-only>
      <CookieLaw v-if="enableCookies" ref="theCookies" @accept="loadCookies">
        <template slot-scope="props">
          <div class="d-flex align-items-center flex-column flex-lg-row w-100">
            <div>
              {{ $t('header.cookies.text') }}
            </div>
            <button @click="props.decline" class="ml-lg-4 btn btn-link">
              {{ $t('header.cookies.button.decline') }}
            </button>
            <button
              @click="props.accept"
              class="btn btn-primary font-weight-bold"
            >
              {{ $t('header.cookies.button.accept') }}
            </button>
          </div>
        </template>
      </CookieLaw>
    </client-only>
  </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law';
export default {
  components: {
    CookieLaw,
  },
  props: {
    ciPath: {
      type: String,
      default: 'https://ci.ryzom.com',
    },
    lang: {
      type: String,
      default: 'en',
    },
    tosLink: {
      type: String,
      default: 'https://www.ryzom.com',
    },
    privacyLink: {
      type: String,
      default: 'https://www.ryzom.com',
    },
    lowPadding: {
      type: Boolean,
      default: false,
    },
    enableCookies: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      forgeLinks: {
        en: 'Portal:Forge',
        de: 'Portal:Forge',
        fr: 'Portail:Forge',
        es: 'Portal:Forge',
        ru: 'Portal:Forge',
      },
    };
  },
  computed: {
    forgeLink() {
      return (
        'https://' +
        this.lang +
        '.wiki.ryzom.com/wiki/' +
        this.forgeLinks[this.lang]
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (localStorage.getItem('cookie:all') && this.enableCookies) {
        this.loadCookies();
      }
    });
  },
  methods: {
    clickTosButton() {},
    clickPrivacyButton() {},
    loadCookies() {
      const jsA = document.createElement('script');
      jsA.setAttribute('type', 'text/javascript');
      jsA.setAttribute(
        'src',
        'https://www.googletagmanager.com/gtag/js?id=GTM-TTQKTDC'
      );
      document.body.appendChild(jsA);

      const jsD = document.createElement('script');
      jsD.setAttribute('type', 'text/javascript');
      jsD.innerHTML =
        'window.fbAsyncInit = function() {\n' +
        '            FB.init({\n' +
        "              appId            : '660483261414502',\n" +
        '              autoLogAppEvents : true,\n' +
        '              xfbml            : true,\n' +
        "              version          : 'v6.0'\n" +
        '            });\n' +
        '          };';
      document.body.appendChild(jsD);

      const jsE = document.createElement('script');
      jsE.setAttribute('type', 'text/javascript');
      jsE.setAttribute('src', 'https://connect.facebook.net/en_US/all.js');
      document.body.appendChild(jsE);

      const jsB = document.createElement('script');
      jsB.setAttribute('type', 'text/javascript');
      jsB.innerHTML =
        'window.dataLayer = window.dataLayer || [];\n' +
        '                    function gtag(){dataLayer.push(arguments);}\n' +
        "                    gtag('js', new Date());\n" +
        "                    gtag('config', 'GTM-TTQKTDC', { 'send_page_view': true });";
      document.body.appendChild(jsB);

      const jsC = document.createElement('script');
      jsC.setAttribute('type', 'text/javascript');
      jsC.innerHTML =
        'function gtag_report_event(event) {\n' +
        "                        gtag('event', event);\n" +
        '                        FB.AppEvents.logEvent(event);\n' +
        '                    }';
      document.body.appendChild(jsC);

      const jsF = document.createElement('script');
      jsF.setAttribute('type', 'text/javascript');
      jsF.innerHTML =
        '!function(f,b,e,v,n,t,s)\n' +
        '  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n' +
        '  n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n' +
        "  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n" +
        '  n.queue=[];t=b.createElement(e);t.async=!0;\n' +
        '  t.src=v;s=b.getElementsByTagName(e)[0];\n' +
        "  s.parentNode.insertBefore(t,s)}(window, document,'script',\n" +
        "  'https://connect.facebook.net/en_US/fbevents.js');\n" +
        "  fbq('init', '543561569681454');\n" +
        "  fbq('track', 'PageView');";
      document.body.appendChild(jsF);
    },
  },
};
</script>
