module.exports = {
  header: {
    forums: 'Forums',
    logout: 'se déconnecter',
    playNow: 'jouez !',
    login: 'se connecter',
    cookies: {
      button: {
        decline: 'Continuez avec les cookies nécessaires uniquement.',
        accept: 'Acceptez tous les cookies.',
      },
      text: 'Ryzom utilise des cookies à des fins de suivi et de marketing.',
    },
    webig: 'Web-Apps',
    billing: 'Compte',
  },
  menu: {
    home: 'Accueil',
    apps: 'Apps',
    forums: 'Forums',
    wiki: 'Wiki',
    chat: 'Chat',
    gameInfo: 'le jeu',
    universe: 'univers',
    events: 'événements',
  },
  footer: {
    owner: 'Winch Gate Property Limited',
    copyright: 'Copyright © {year}. Tous droits réservés.',
    pegi: {
      online: 'Jeu en ligne',
      violence: 'Violence',
    },
    privacy: 'Politique de confidentialité',
    tos: "Conditions d'utilisation",
    community: {
      bmsite: 'Ballistic Mystix',
      armory: 'Ryzom Armory',
      forge: 'Ryzom Forge',
      wiki: 'Wiki',
      title: 'Communauté',
    },
    follow: {
      youtube: 'Youtube',
      twitter: 'Twitter',
      vimeo: 'Vimeo',
      flickr: 'Flickr',
      facebook: 'Facebook',
      title: 'Suivre Ryzom',
    },
    support: {
      feedback: 'Vos retours',
      forums: 'Forums',
      contact: 'Nous contacter',
      title: 'Assistance',
    },
  },
  premium: {
    button: {
      manageSub: 'Gérer votre abonnement',
    },
    sub: {
      button: 'prendre un abonnement',
      title: 'Vous jouez  \ndéjà ?',
    },
    compare: {
      title: 'Comparez ! Le choix vous appartient.',
    },
    comparison: {
      premium: {
        maxlevel: 'Niveau maximal 250',
        xp: 'XP double',
        maxquality: "Qualité maximale de l'équipement 250",
        storage:
          '* Une monture & trois animaux de bât\n* Un appartement\n* Un hall de guilde',
        title: 'premium',
        support:
          "* Aide de l'équipe d'assistance\n* Restauration des anciens comptes (ouverts avant la fusion des serveurs) pour retrouver les personnages\n* Restauration de personnage(s) en cas d'effacement volontaire ou accidentel\n* Transfert de personnage(s) entre comptes Premium souscrits par la même personne\n* Changement de pseudonyme autorisé tous les six mois pour chaque personnage",
      },
      free: {
        maxlevel: 'Niveau maximal 125',
        xp: 'XP ordinaire',
        maxquality: "Qualité maximale de l'équipement 150",
        storage: 'Une monture',
        title: 'gratuit',
        support:
          "* Aide de l'Équipe d'Assistance\n* Un changement de pseudonyme autorisé pour chacun de vos personnages",
      },
    },
    intro: {
      text:
        "En publiant son code, Ryzom a ouvert le jeu au monde du Libre, ce qui en fait le seul MMORPG open-source, jouable sur Windows, LInux et Mac.\n\nAssurer la maintenance de Ryzom implique cependant des coûts, tels que la rémunération de la petite équipe d'employés et l'achat de serveurs. C'est pourquoi, même si on peut jouer à Ryzom en _Free to Play_, les souscriptions d'abonnement sont, pour lui, vitales.\n\nPour remercier les joueurs choisissant de s'abonner, certaines fonctionnalités du jeu sont débloquées pour eux et certains bonus leurs sont offerts : la possibilité d'emmener leurs personnages au niveau 250 au lieu de 125, le doublement des gains de points d'expérience et l'accès à des moyens de stockage supplémentaires.\n\n**Pour soutenir Ryzom, faites le choix de l'abonnement !**",
      title: 'Pourquoi payer ?',
    },
    rates: {
      perMonth: ' mois',
      w1: {
        title: 'Abonnement d\'une semaine',
      },
      m1: {
        title: 'Abonnement mensuel ',
      },
      m3: {
        title: 'Abonnement trimestriel  ',
      },
      y1: {
        title: 'Abonnement annuel ',
      },
      m6: {
        title: 'Abonnement semestriel  ',
      },
      y99: {
        title: 'Abonnement à vie',
      },
      title: 'Tarifs',
      lifetime: 'Les personnages du compte abonné à vie reçoivent le titre spécial d’« Ambassadeur de Ryzom » / « Ambassadrice de Ryzom ».',
    },
    donate: {
      button: 'faire un don',
      title: 'Aidez la Saga\nà se poursuivre !',
    },
    title: "Soutenez Ryzom et bénéficiez d'avantages !",
    signup: {
      button: 'Souscrivez et commencez à jouer',
      title: 'Intéressé par  \nRyzom ?',
    },
  },
};
