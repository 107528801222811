module.exports = {
  header: {
    forums: 'Forum',
    logout: 'ausloggen',
    playNow: 'jetzt spielen!',
    login: 'einloggen',
    cookies: {
      button: {
        decline: 'Nur notwendige Cookies.',
        accept: 'Alle Cookies akzeptieren.',
      },
      text: 'Ryzom verwendet Cookies für Analyse- und Marketingzwecke.',
    },
    webig: 'Web-Apps',
    billing: 'Account',
  },
  menu: {
    home: 'Start',
    apps: 'Apps',
    forums: 'Foren',
    wiki: 'Wiki',
    chat: 'Chat',
    gameInfo: 'spiel',
    universe: 'universum',
    events: 'events',
  },
  footer: {
    owner: 'Winch Gate Property Limited',
    copyright: 'Copyright © {year}. Alle Rechte vorbehalten.',
    pegi: {
      online: 'Online-Spiel',
      violence: 'Gewalt',
    },
    privacy: 'Datenschutzbestimmungen',
    tos: 'Nutzungsbedingungen',
    community: {
      bmsite: 'Ballistic Mystix',
      armory: 'Ryzom Armory',
      forge: 'Ryzom Forge (das Gemeinschaftsprojekt)',
      wiki: 'Ryzom-Wiki',
      title: 'Gemeinschaft',
    },
    follow: {
      youtube: 'Youtube',
      twitter: 'Twitter',
      vimeo: 'Vimeo',
      flickr: 'Flickr',
      facebook: 'Facebook',
      title: 'Folge Ryzom',
    },
    support: {
      feedback: 'Feedback',
      forums: 'Foren',
      contact: 'Kontakt',
      title: 'Support',
    },
  },
  premium: {
    button: {
      manageSub: 'Abonnement verwalten',
    },
    sub: {
      button: 'Abonnement abschließen',
      title: 'Schon am  \nSpielen?',
    },
    compare: {
      title: 'Vergleiche und entscheide selbst!',
    },
    comparison: {
      premium: {
        maxlevel: 'Maximaler Level 250',
        xp: 'doppelte Erfahrung',
        maxquality: 'Maximale Stufe der Ausrüstung 250',
        storage: '* 1 Reittier + 3 Packtiere\n* 1 Apartment\n* 1 Gildenhalle',
        title: 'premium',
        support:
          '* Unterstützung durch das Support-Team.\n* Wiederherstellung alter Konten (die vor der Zusammenführung der\nSpielserver existierten), um einen oder mehrere Charaktere wiederherzustellen.\n* Wiederherstellung von einen oder mehreren Charakteren im Falle einer absichtlichen oder versehentlichen Löschung\n* Übertragung von einen oder mehreren Charakteren zwischen zwei Premium-Konten, die derselben Person gehören\n* Ein Wechsel des Pseudonyms ist alle sechs Monate für jeden Charakter erlaubt',
      },
      free: {
        maxlevel: 'Maximaler Level 125',
        xp: 'normale Erfahrung',
        maxquality: 'Maximale Stufe der Ausrüstung 150',
        storage: '* 1 Reittier',
        title: 'gratis',
        support:
          '* Unterstützung durch das Support-Team.\n* Eine Änderung des Pseudonyms ist für jeden Charakter erlaubt',
      },
    },
    intro: {
      text:
        'Ryzom hat das Spiel für die freie Welt geöffnet, seinen Quellcode veröffentlicht und ist das einzige quelloffene MMORPG geworden, das unter Windows, Linux und Mac gespielt werden kann.\n\nDie Pflege von Ryzom ist jedoch mit Kosten verbunden, wie zum Beispiel die Vergütung für das kleine Team von Mitarbeitern und die Bezahlung der Server. Deshalb, obwohl Ryzom kostenlos spielbar (F2P) ist, sind Abonnements unerlässlich, damit das Spiel weitergeführt werden kann.\n\nUm sich bei den Spielern zu bedanken, die sich für ein Abonnement entschieden haben, werden bestimmte Funktionen des Spiels freigeschaltet und gewisse Vorteile angeboten: die Möglichkeit, Charaktere auf Stufe 250 statt 125 zu erhöhen, die Verdoppelung aller gewonnenen Erfahrungspunkte und der Zugang zu verschiedenen anderen Aufbewahrungsmöglichkeiten für Gegenstände.\n\n**Um Ryzom zu helfen, entscheidet euch für ein Abonnement!**',
      title: 'Warum bezahlen?',
    },
    rates: {
      perMonth: 'Monat',
      w1: {
        title: '1-Woche Abonnement',
      },
      m1: {
        title: '1-Monat Abonnement',
      },
      m3: {
        title: '3-Monate Abonnement',
      },
      y1: {
        title: '1-Jahr Abonnement',
      },
      m6: {
        title: '6-Monate Abonnement',
      },
      y99: {
        title: 'Lifetime Abonnement',
      },
      title: 'Preise',
      lifetime: 'Charactere auf Accounts mit einem Lifetime-Abo erhalten den Titel “Abgesandte/r von Ryzom”',
    },
    donate: {
      button: 'spenden',
      title: 'Hilf uns die Saga  \nfortzuführen!',
    },
    title: 'Unterstütze Ryzom und profitiere!',
    signup: {
      button: 'Anmelden und loslegen',
      title: 'Interesse an  \nRyzom?',
    },
  },
};
