<template>
  <html>
    <body>
      <ryzom-footer v-bind="$props"></ryzom-footer>
    </body>
  </html>
</template>

<script>
import VueI18n from 'vue-i18n';
import { ImagePlugin } from 'bootstrap-vue';

import Vue from 'vue';

import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare';
import { faVimeoSquare } from '@fortawesome/free-brands-svg-icons/faVimeoSquare';
import { faFlickr } from '@fortawesome/free-brands-svg-icons/faFlickr';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';

import langEN from './lang/en-US';
import langDE from './lang/de-DE';
import langFR from './lang/fr-FR';
import langES from './lang/es-ES';
import langRU from './lang/ru-RU';
import RyzomFooter from './components/RyzomFooter';

Vue.use(VueI18n);
Vue.use(ImagePlugin);

config.autoAddCss = false;
config.keepOriginalSource = false;

library.add(faFacebook, faTwitterSquare, faVimeoSquare, faFlickr, faYoutube);

Vue.component('font-awesome-icon', FontAwesomeIcon);

export default {
  i18n: new VueI18n({
    locale: 'en',
    messages: {
      en: langEN,
      de: langDE,
      fr: langFR,
      es: langES,
      ru: langRU,
    },
  }),
  components: { RyzomFooter },
  props: {
    ciPath: {
      type: String,
      default: 'https://ci.ryzom.com',
    },
    lang: {
      type: String,
      default: 'en',
    },
    tosLink: {
      type: String,
      default: 'https://ryzom.com/terms-of-service',
    },
    privacyLink: {
      type: String,
      default: 'https://ryzom.com/privacy',
    },
    lowPadding: {
      type: Boolean,
      default: false,
    },
    enableCookies: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$i18n.locale = this.lang;
  },
};
</script>

<style lang="scss">
/*@import "~bootstrap/scss/functions";
    @import "../scss/bootstrap/variables";
    @import "../scss/common/effects";
    @import "~bootstrap/scss/variables";
    @import "~bootstrap/scss/mixins";
    @import "~bootstrap/scss/utilities";*/
@import 'scss/min';
@import 'scss/cookielaw';
@import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css';

@import 'scss/layout/footer';
</style>
