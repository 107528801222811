module.exports = {
  header: {
    forums: 'Forums',
    logout: 'logout',
    playNow: 'play now!',
    login: 'login',
    cookies: {
      button: {
        decline: 'Continue with necessary cookies only.',
        accept: 'Accept all cookies.',
      },
      text: 'Ryzom is using cookies for tracking and marketing purposes.',
    },
    webig: 'Web-Apps',
    billing: 'Account',
  },
  menu: {
    home: 'Home',
    apps: 'Apps',
    forums: 'Forums',
    wiki: 'Wiki',
    chat: 'Chat',
    gameInfo: 'game info',
    universe: 'universe',
    events: 'events',
  },
  footer: {
    owner: 'Winch Gate Property Limited',
    copyright: 'Copyright © {year}. All rights reserved.',
    pegi: {
      online: 'Online Gameplay',
      violence: 'Violence',
    },
    privacy: 'Privacy Policy',
    tos: 'Terms of Service',
    community: {
      bmsite: 'Ballistic Mystix',
      armory: 'Ryzom Armory',
      forge: 'Ryzom Forge',
      wiki: 'Ryzom Wiki',
      title: 'Community',
    },
    follow: {
      youtube: 'Youtube',
      twitter: 'Twitter',
      vimeo: 'Vimeo',
      flickr: 'Flickr',
      facebook: 'Facebook',
      title: 'Follow Ryzom',
    },
    support: {
      feedback: 'Feedback',
      forums: 'Forums',
      contact: 'Contact',
      title: 'Support',
    },
  },
  premium: {
    button: {
      manageSub: 'Manage your subscription',
    },
    sub: {
      button: 'add a subscription',
      title: 'Already  \nplaying?',
    },
    compare: {
      title: 'Compare! The choice is yours.',
    },
    comparison: {
      premium: {
        maxlevel: 'Maximum level 250',
        xp: 'Double XP',
        maxquality: 'Maximum quality of gear 250',
        storage:
          '* One mount & three packers\n* One apartment\n* One guild hall',
        title: 'premium',
        support:
          '* Assistance from the Support Team\n* Restoration of old accounts (subscribed prior to the merge of the\ngame servers) in order to recover the characters\n* Restoration of character(s) in case of voluntary or accidental deletion\n* Transfer of character(s) between two Premium accounts belonging to the same person\n* A change of pseudonym allowed every six months for each character',
      },
      free: {
        maxlevel: 'Maximum level 125',
        xp: 'Normal XP',
        maxquality: 'Maximum quality of gear 150',
        storage: 'One mount',
        title: 'free',
        support:
          '* Assistance from the Support Team\n* A change of pseudonym allowed for each character',
      },
    },
    intro: {
      text:
        'Ryzom has opened the game to the world of Free, releasing its code and becoming the only open source MMORPG, playable on Windows, Linux and Mac.\n\nHowever, maintaining Ryzom does involve costs, such as compensation for the small team of employees and payment for servers. That is why, even though Ryzom is playable as Free to Play, subscriptions are vital for it to continue.\n\nTo thank the players chosing to subscribe, certain features of the game are unlocked and certain bonuses are offered to them: an opportunity to level their characters to level 250 instead of 125, doubling all experience points gained, and access to several other means of storage.\n\n**To help Ryzom, make the choice to subscribe!**',
      title: 'Why pay?',
    },
    rates: {
      perMonth: ' month',
      w1: {
        title: '1-week subscription',
      },
      m1: {
        title: '1-month subscription',
      },
      m3: {
        title: '3-months subscription',
      },
      y1: {
        title: '1 year subscription',
      },
      m6: {
        title: '6-months subscription',
      },
      y99: {
        title: 'Lifetime subscription',
      },
      title: 'Rates',
      lifetime: 'Characters of a Lifetime Subscriber account receive the special title of "Ambassador of Ryzom",',
    },
    donate: {
      button: 'make a donation',
      title: 'Help the Saga  \ncontinue!',
    },
    title: 'Support Ryzom and benefit!',
    signup: {
      button: 'Sign up and start playing',
      title: 'Interested in  \nRyzom?',
    },
  },
};
