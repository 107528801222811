module.exports = {
  header: {
    forums: 'Foros',
    logout: 'cerrar sesión',
    playNow: '¡juega ahora!',
    login: 'iniciar sesión',
    cookies: {
      button: {
        decline: 'Continúe sólo con las galletas necesarias.',
        accept: 'Acepta todas las galletas.',
      },
      text:
        'Ryzom está utilizando cookies con fines de seguimiento y comercialización.',
    },
    webig: 'Web-Apps',
    billing: 'Cuenta',
  },
  menu: {
    home: 'Inicio',
    apps: 'Apps',
    forums: 'Foros',
    wiki: 'Wiki',
    chat: 'Chat',
    gameInfo: 'el juego',
    universe: 'universo',
    events: 'eventos',
  },
  footer: {
    owner: 'Winch Gate Property Limited',
    copyright: 'Copyright © {año}. Todos los derechos reservados.',
    pegi: {
      online: 'Juego online',
      violence: 'Violencia',
    },
    privacy: 'Política de privacidad',
    tos: 'Términos de Servicio',
    community: {
      bmsite: 'Ballistic Mystix',
      armory: 'Armería Ryzom',
      forge: 'Ryzom Forge',
      wiki: 'Ryzom Wiki',
      title: 'Comunidad',
    },
    follow: {
      youtube: 'Youtube',
      twitter: 'Twitter',
      vimeo: 'Vimeo',
      flickr: 'Flickr',
      facebook: 'Facebook',
      title: 'Sigue a Ryzom',
    },
    support: {
      feedback: 'Comentarios',
      forums: 'Foros',
      contact: 'Contacto',
      title: 'Soporte técnico',
    },
  },
  premium: {
    button: {
      manageSub: 'Administre su suscripción',
    },
    sub: {
      button: 'añadir una suscripción',
      title: 'Ya  \n¿Jugando?',
    },
    compare: {
      title: '¡Compare! La elección es tuya.',
    },
    comparison: {
      premium: {
        maxlevel: 'Nivel máximo 250',
        xp: 'Doble XP',
        maxquality: 'Máxima calidad del equipo 250',
        storage:
          '* Una montura y tres empaquetadores\n* Un apartamento\n* Una sala del gremio',
        title: 'premium',
        support:
          '* Asistencia del equipo de soporte\n* Restauración de cuentas antiguas (suscritas antes de la fusión de la\nservidores de juegos) para recuperar los personajes\n* Restauración del carácter o los caracteres en caso de supresión voluntaria o accidental\n* Transferencia de carácter(es) entre dos cuentas Premium pertenecientes a la misma persona\n* Se permite un cambio de seudónimo cada seis meses para cada personaje\n\n',
      },
      free: {
        maxlevel: 'Nivel máximo 125',
        xp: 'XP normal',
        maxquality: 'Máxima calidad del equipo 150',
        storage: 'Una montura',
        title: 'gratis',
        support:
          ' * Asistencia del equipo de soporte\n * Se permite un cambio de seudónimo para cada personaje',
      },
    },
    intro: {
      text:
        'Ryzom ha abierto el juego al mundo de lo gratuito, liberando su código y convirtiéndose en el único MMORPG de código abierto, jugable en Windows, Linux y Mac.\n\nSin embargo, el mantenimiento de Ryzom implica costes, como la compensación del pequeño equipo de empleados y el pago de los servidores. Por eso, aunque Ryzom se puede jugar como Free to Play, las suscripciones son vitales para que continúe.\n\nPara agradecer a los jugadores que deciden suscribirse, se desbloquean ciertas características del juego y se les ofrecen ciertos bonos: la oportunidad de nivelar sus personajes hasta el nivel 250 en lugar de 125, el duplicar todos los puntos de experiencia ganados y el acceso a varios otros medios de almacenamiento.\n\n**Para ayudar a Ryzom, elige la opción de suscribirse.',
      title: '¿Por qué pagar?',
    },
    rates: {
      perMonth: 'mes',
      w1: {
        title: '1 semana de subscripción',
      },
      m1: {
        title: 'Suscripción de 1 mes',
      },
      m3: {
        title: 'Suscripción de 3 meses',
      },
      y1: {
        title: 'Suscripción de 1 año',
      },
      m6: {
        title: 'Suscripción de 6 meses',
      },
      y99: {
        title: 'Permanente',
      },
      title: 'Tarifas',
      lifetime: 'Personajes en cuentas de Suscripción Permanente reciben el título especial de “Embajador de Ryzom”.',
    },
    donate: {
      button: 'aportar una donación',
      title: 'Ayude a la Saga  \n¡Continúa!',
    },
    title: 'Apoya a Ryzom y benefíciate!',
    signup: {
      button: 'Regístrate y empieza a jugar',
      title: 'Interesado en  \n¿Ryzom?',
    },
  },
};
